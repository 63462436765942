import React from 'react';
import './Terms.css';

function Terms() {
    return (
        <div className='terms-container' >
            <div className='terms-text'>
                <span className='terms-title'>Gotham Advanced Media and Entertainment Terms of use </span>
                <p>Last Updated: January 4, 2024</p>
                <p>PLEASE READ THESE TERMS OF USE CAREFULLY, AS THEY AFFECT YOUR LEGAL RIGHTS AND OBLIGATIONS. PLEASE NOTE THAT, TO THE EXTENT PERMITTED BY APPLICABLE LAW, BY AGREEING TO THESE TERMS, YOU WAIVE THE RIGHT TO PARTICIPATE IN A REPRESENTATIVE PROCEEDING, INCLUDING BUT NOT LIMITED TO A CLASS ACTION PROCEEDING.</p>
                <span><b>•	Introduction</b></span>
                <p>These Terms of Use (including any future modifications of these Terms of Use, the “Terms”) govern your use of our website located at [gothamadvanced.com (the “Site”), which is made available by Gotham Advanced Media and Entertainment, LLC (“GAME,” “we,” “us,” “our”), and your communications with us via email or otherwise, and any and all other content or services made available via the Site or any other location where these Terms are posted (collectively, the “Services”). </p>
                <p>These terms are a contract between you and us. By using the Services in any way, including, without limitation, visiting our website, emailing us via the email address available on the Services, and/or submitting information to the Services (including via email to us), you agree to these Terms. By agreeing to these Terms, you acknowledge that you have also read and understood our Privacy Policy, which is incorporated into and made part of these Terms. <b>IF YOU DO NOT AGREE TO THESE TERMS OR OUR PRIVACY POLICY, YOU MUST NOT USE THE SERVICES.</b></p>
                <p>From time to time, we may update these Terms by posting the updated version on the Services and updating the “Last updated” date. By continuing to use the Services after the Terms have been updated, you indicate that you accept the updated Terms. If you do not agree to the updated Terms, please do not use the Services.</p>

                <p> The use of our products and services may be governed by different or additional terms or policies. Unless otherwise stated in the relevant terms or policies, these Terms in no way limit our rights or define our obligations regarding our products and services other than the Services.</p>
                <span>	<b>•	Your Use of the Services</b></span>
                <p>
                    The Services provide information about our products and services and provide an email address by which you may contact us. When using the Services, including when communicating with us via email, you represent that all information you provide is, to your knowledge, accurate and up to date. Any activity that takes place under your name or email address, including communications with us, are your responsibility. You agree to notify us immediately if you believe someone has contacted us under your name or email address without your authorization. We are not liable for any loss that you may incur because of someone else using the Services under your name or email address, either with or without your knowledge.
                </p>
                <p>In using the Services, you represent and warrant that you are at least eighteen (18) years of age, and you will use the Services in accordance with these Terms. You also represent and warrant that you will not:</p>
                <p>	•	Use the Services in any way or for any purpose that is unlawful, fraudulent, harmful, tortious, defamatory, libelous, obscene, invasive of the privacy of another person, threatening, harassing, abusive, hateful, racist, infringing, pornographic, violent, or otherwise objectionable or inappropriate as determined by us in our sole discretion.</p>
                <p>  •	Infringe any rights of GAME or any third party, including any intellectual property, publicity, or privacy rights, in your use of the Services.</p>
                <p>•	Provide us with information that you are not authorized to share with us, including any information that you are under a legal or contractual obligation to keep private or confidential.</p>
                <p>•	Transmit through the Services or to us any unauthorized communication, including but not limited to bulk or “junk” emails, chain letters, spam, or any advertising or promotional materials or any other form of solicitation.</p>
                <p>•Interfere with, disrupt, or create undue burden on the Services or the networks or services connected to the Services by means including, but not limited to, hacking into the Services or otherwise tampering with the security or operation of the Services.</p>
                <p>•	Use the Services or Our Content (as defined below) to construct any kind of database, machine learning model or algorithm, or other software, data, or content.</p>
                <p>	•	Crawl or scrape (whether manually or automatically), or use any data mining, robots, virus, worms, bugs, or other data gathering or extraction tools on the Services or frame any portion of the Services.</p>
                <p>	•	Circumvent any of the Services’ security measures, reverse engineer any portion of the Services, obtain the Services’ source code, or create back doors or any form of unauthorized access to the Services.</p>
                <p>•	Provide us with or use the Services to disseminate any material that contains viruses, corrupted files, Trojan horses, worms, or any other computer code, software, files, or programs which might interrupt, limit, or interfere with the functionality of any computer software or hardware or telecommunications equipment or that may adversely affect the operation of the Services.</p>
                <p>	•	Attempt, or encourage or assist any other person, to take any of the above actions.</p>
                <span>	<b>•	Intellectual Property Rights</b></span>
                <p>	•	Our Content
                </p>
                <p>Content on the Services that is provided by us or our licensors, including certain text, graphics, photographs, artwork, images, digitally downloadable files, trademarks, logos, product and program names, and the compilation of the foregoing (“Our Content”) is the property of GAME and our licensors, and is protected in the U.S. and internationally under trademark, copyright, and other intellectual property laws.</p>
                <p>These Terms grant you no right or license in or to Our Content that is not expressly provided herein. You may not copy, reproduce, distribute, transfer, sell, license, publish, enter a database, display, perform publicly, modify, create derivative works, upload, edit, post, link to, frame, transmit, rent, lease, lend or sublicense or in any way exploit any part of the Services or Our Content. You agree not to download, display, or use any of Our Content located on the Services for use in any brochures, marketing materials, publications, in public performances, on websites other than the Service, or in any other medium for any commercial purpose. You also agree not to download, display, or use any of Our Content in connection with products or services that are not ours in any other manner likely to cause confusion among consumers, disparage or discredit us and/or our licensors, or otherwise infringe our or our licensors’ intellectual property rights. You further agree to in no other way misuse any of Our Content or any third-party content that appears on the Services.</p>
                <p>	•	Information You Provide
                </p>
                <p>When you interact with us via email or otherwise, you may choose to provide us with information, including comments, feedback, and personal information. By providing us with information, you hereby grant us a limited, non-exclusive, sub-licensable, worldwide, fully-paid, royalty-free license to use, modify, reproduce, and distribute such information in any and all media for our business purposes, including developing our products and services and providing information to third parties, without the requirement to make payment to you or to any third party or the need to seek any third-party permission. You represent and warrant that you have the right to grant the license set forth herein, and our use of information that you provide, consistent with this license, does not and will not violate the privacy rights, publicity rights, copyrights, trademark rights, patents, contract rights, or any other intellectual property rights or other rights of any person or entity.</p>
                <p>In addition, by accessing the Services, you acknowledge and agree that any materials, ideas, or other communications or information you transmit to us in any manner and for any reason will not be treated as confidential or proprietary. You understand that you have no ownership rights in any ideas you may submit to us via email or otherwise, and you expressly disclaim any rights or causes of action you may have with respect to any information you may submit. You also agree that we have the right (as defined herein), but no obligation, to use any comments, feedback, suggestions, or other ideas you provide to us.</p>
                <p>If you provide us with personal information, you consent to our collection, use, and disclosure of such information as described in our Privacy Policy.
                </p>
                <span>	<b>•	Third Party Websites</b></span>
                <p>The Services may link to websites not maintained or operated by or related to us. Links are provided as a service to you and are not sponsored by or affiliated with us or the Services, and we make no representations or warranties about the content, completeness, or accuracy of those third-party websites. We have not reviewed the material, including goods and services, made available through third-party websites. These Terms do not apply to your use of third-party websites, so you should review the third party’s terms, policies, and other documents.</p>
                <span>	<b>•	Consent to Electronic Communications</b></span>
                <p>You consent to receiving electronic communications from us. These communications may include notices about our policies and terms, including these Terms, and information related to the Services. You agree that any notices, agreements, disclosures, or other communications that we send to you electronically will satisfy any legal communication requirements, including that such communications be in writing.
                </p>
                <span>	<b>•	Access to the Services
                </b></span>

                <p>We may, in our sole discretion and at any time, discontinue the Services or any part thereof, with or without notice, or may prevent your use of the Services with or without notice to you. You agree that you do not have any rights in the Services and that we will have no liability to you if the Services are discontinued or your ability to access the Services, and any information you have provided via the Services, is terminated. We may restrict or terminate your access to the Services at any time, and without notice, if we deem that you have violated these Terms, the law, or for any other reason.
                </p>
                <span>	<b>•	Reliability and Accuracy</b></span>
                <p>Despite our efforts, the information on the Services may be inaccurate, incomplete, or out of date. We make no representations or warranties as to the reliability, completeness, accuracy, or correctness of any information on the Services, and we are not responsible for your reliance on or use of such information. You acknowledge that any reliance on such material or systems will be at your own risk. We also make no representations regarding the amount of time that any of Our Content or any information you provide to us will be preserved. </p>
                <span>	<b>•	Disclaimer of Warranties
                </b></span>
                <p>THE SERVICES ARE PROVIDED ON AN “AS IS, AS AVAILABLE” BASIS. NO WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THOSE OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, ARE MADE WITH RESPECT TO THE SERVICES OR ANY INFORMATION OR SOFTWARE THEREIN. WE DO NOT WARRANT UNINTERRUPTED USE OR OPERATION OF THE SERVICES OR YOUR ACCESS TO ANY CONTENT. NO ADVICE OR INFORMATION, ORAL OR WRITTEN, OBTAINED FROM THE SERVICES WILL CREATE ANY WARRANTY THAT IS NOT EXPRESSLY STATED IN THESE TERMS.</p>
                <span>	<b>•	Limitation of Liability
                </b></span>
                <p>TO THE FULLEST EXTENT ALLOWABLE BY APPLICABLE LAW, WE SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL DAMAGES (COLLECTIVELY, “DAMAGES”) THAT RELATE TO THE USE OF OR INABILITY TO USE THE SERVICES, NOR SHALL WE BE RESPONSIBLE FOR ANY DAMAGES WHATSOEVER THAT RESULT FROM MISTAKES, OMISSIONS, INTERRUPTIONS, DELETIONS, ERRORS, DEFECTS, DELAYS, OR ANY FAILURE OF PERFORMANCE WHETHER OR NOT CAUSED BY EVENTS BEYOND OUR REASONABLE CONTROL, INCLUDING BUT NOT LIMITED TO ACTS OF GOD, COMMUNICATIONS LINE FAILURE, THEFT, DESTRUCTION, OR UNAUTHORIZED ACCESS TO THE SERVICES. </p>
                <p>TO THE FULLEST EXTENT ALLOWABLE UNDER APPLICABLE LAW, WE AND OUR AFFILIATES OR AGENTS SHALL NOT BE LIABLE FOR ANY DAMAGE OF ANY KIND, INCLUDING BUT NOT LIMITED TO DAMAGE DUE TO A NEGLIGENT ACT, RELATED TO THE USE OF OR THE INABILITY TO USE THE SERVICES, INCLUDING, WITHOUT LIMITATION, OUR USE OR DISTRIBUTION OF USER CONTENT, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. </p>
                <p>TO THE FULLEST EXTENT ALLOWABLE BY APPLICABLE LAW, THE MAXIMUM TOTAL LIABILITY OF US AND OUR AGENTS, SPONSORS, AFFILIATES, AND LICENSORS TO YOU FOR ALL CLAIMS UNDER THESE TERMS, WHETHER IN CONTRACT, TORT, OR OTHERWISE, IS TWENTY-FIVE DOLLARS ($25).</p>
                <span>	<b>•	Indemnification
                </b></span>
                <p>You agree to indemnify and hold us and our affiliates, contractors, and agents, and each of their officers, directors, employees, and agents harmless from any claims, damages, and expenses, including reasonable attorneys’ fees and costs, related to, or arising from your use of the Services or violation of these Terms.</p>
                <span>	<b>•	Governing Law; Disputes
                </b></span>
                <p>These Terms, and any claims, suits, proceedings, and other actions of any kind arising out of, relating to, or concerning these Terms and the discussions contemplated hereby (“Claims”), shall be interpreted and construed under the substantive laws of the State of New York without reference to its conflict of laws principles, except to the extent governed by the United States Trademark Act of 1946 or other superseding federal law. For all Claims, each party hereto irrevocably and unconditionally consents to submit to the exclusive jurisdiction of the federal and state courts located in New York County, New York. Each party agrees not to commence any Claim relating thereto except in such courts, and irrevocably and unconditionally waives any objection to the laying of the venue of any Claim in such courts.
                </p>
                <p>To the extent permitted by applicable law, any Claim must be brought within two (2) years of the date such Claim first accrued. ALL CLAIMS MUST BE BROUGHT IN EACH PARTY’S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION OR SIMILAR, COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION, OR OTHER REPRESENTATIVE PROCEEDING. YOU AND GAME ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION, COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION, OR OTHER REPRESENTATIVE PROCEEDING OF ANY KIND TO THE EXTENT PERMITTED BY APPLICABLE LAW.</p>
                <span>	<b>•	Injunctive Relief
                </b></span>
                <p>You acknowledge that irreparable injury may result to us upon the unauthorized use of Our Content and our trademarks and/or unauthorized disclosure of our confidential and/or proprietary information and that money damages may not be a sufficient remedy for any such harm. You therefore agree that upon any act or threatened act of trademark infringement or unauthorized disclosure of information which we believe is confidential and/or proprietary, we shall be entitled, in addition to such other remedies now or hereafter available at law, in equity, by statute or otherwise, to seek damages and relief as may be available under applicable law, including obtaining a temporary restraining order and/or a permanent injunction prohibiting you from engaging in any such act or specifically enforcing these Terms, as the case may be, without proof of damages or posting a bond.</p>
                <span>	<b>18. Miscellaneous
                </b></span>
                <p>
                    •	These Terms constitute the entire agreement between you and GAME regarding the Services and your use thereof.<br></br>
                    •	The section titles in these Terms are for convenience only and have no legal or contractual effect.<br></br>
                    •	Our failure to comply with these Terms because of an act of God, war, fire, riot, terrorism, earthquake, actions of federal, state or local governmental authorities, or for any other reason beyond our reasonable control, shall not be deemed a breach of these Terms.<br></br>
                    •	If we fail to act with respect to your breach or anyone else’s breach on any occasion, we are not waiving its right to act with respect to future or similar breaches.<br></br>
                    •	All remedies under these Terms shall be cumulative and not exclusive.<br></br>
                    •	If any provision of these Terms shall be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from these terms of use and shall not affect the validity and enforceability of any remaining provisions.<br></br>
                    •	These Terms form no partnership between you and GAME, and neither you nor GAME have the power or the authority to obligate or bind the other.<br></br>
                    •	You may not assign your rights under these Terms to any third party. We may assign our rights under this Terms without condition.<br></br>
                </p>
            </div>
        </div>
    )
}

export default Terms