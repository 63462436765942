import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.css';
import Home from './pages/Home/Home';
import Terms from './pages/Terms/Terms';
import Privacy from './pages/Privacy/Privacy';
import './assets/styles/_ui.scss'

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />

        </Routes>
      </Router>
    </>
  );
}

export default App;
