import React from 'react';
import './Privacy.css'

function Privacy() {
    return (
        <div className='privacy-container'>
            <div className='privacy-text'>
                <span className='privacy-title'>Gotham Advanced Media and Entertainment Privacy Policy </span>
                <p>Last Updated: January 4, 2024</p>
                <p>This Privacy Policy (“Policy”) describes how Gotham Advanced Media and Entertainment, LLC (“GAME,” “we,” “us,” “our”) collects, uses, and discloses information from and about you via our website located at gothamadvanced.com and via any other online or offline interactions you have with us where this Policy is posted (collectively, the “Services”). By using the Services, you agree to this Policy.</p>
                <p><b>Please read this Policy carefully to understand how we handle your information. By using the Services, you acknowledge that you have read and understand this Policy and that we collect, use, and share your information as set forth below. IF YOU DO NOT AGREE WITH THIS POLICY, YOU MUST NOT USE THE SERVICES.</b></p>
                <p>The use of our products and services may be governed by different or additional privacy policies, as indicated when using such products or services. Unless otherwise stated in the relevant privacy policies, this Policy in no way limits our rights or defines our obligations regarding our products and services other than the Services.</p>
                <p><b>•	Information We Collect</b></p>
                <p>•	Information You Provide</p>
                <p>We collect information that you provide to us when you interact with us or the Services, such as when you visit our website or send us an email. This information may include, but is not limited to, your email address and anything you choose to include in your emails or other communications with us, which could include, without limitation, your name, job title, and the company for whom you work.</p>
                <p>•	Information Collected Automatically</p>
                <p>When you interact with our online Services, we and our vendors may collect certain information about your automatically via cookies, pixels/web beacons, and similar technologies. Cookies are small text files placed on your device that perform a variety of functions, including recognizing your device, remembering your preferences, enabling the Services to function, assisting with security, and improving our advertising and analytics. Cookies and similar technologies may collect technical information about your device and your use of the Services, such as your IP address, device identifiers, and information about your web browser (such as language and preferences), operating system, device (such as model and manufacturer), and activity on our online services (such as pages you visit, links you click, and the webpages you visit before and after visiting the Services). We or our vendors may also infer your general location from your IP address.</p>
                <p>	•	Information From Other Sources
                </p>
                <p>We may collect information about you from other sources, such as social media networks (for example, if you like our posts, follow us, or connect with us on social media networks such as LinkedIn). </p>
                <p><b>	•	How We Use Information
                </b></p>
                <p>We may use the information we collect in various ways, depending on the type of information, including for the following purposes: </p>
                <p>
                    •	To provide and maintain the Services;<br />
                    •	To communicate with you, including to provide information about our products and services, notify you of changes to our terms and policies, or respond to your questions or requests;<br />
                    •	To market our products and services to you, including via email (subject to any opt-out preferences you have communicated to us);<br />
                    •	To maintain the security and integrity of the Services and for fraud prevention;<br />
                    •	To comply with legal requirements, relevant industry standards, and our policies;<br />
                    •	To protect the rights, safety, property, or security of GAME, our users, employees, third parties, members of the public and/or our Services;<br />
                    •	To exercise our legal rights and enforce our terms and policies; and<br />
                    •	For any other purpose, subject to obtaining any required consent.<br />
                </p>
                <p>We may combine information that we collect automatically or that you provide to us with information that we obtain from other sources for any of the purposes above.</p>
                <p>We may aggregate or de-identify any information we collect. We may use and disclose aggregated or de-identified information for any purpose and to any third parties, in our discretion. </p>
                <p><b>	•	How We Disclose Information
                </b></p>
                <p>We disclose the information we collect as follows:
                </p>
                <p><b>	•	Internally and to Affiliates.</b> GAME employees may access your information, and we may provide information to our corporate affiliates, for the purposes above.</p>
                <p><b>•	Legal matters and safety.</b> We may disclose your information as we deem necessary or appropriate to comply with applicable laws and regulations, respond to legal process (such as subpoenas or regulatory inquiries), obtain legal counsel, exercise our legal rights (including to enforce our terms and policies), or protect the rights, safety, property, or security of GAME, our users, employees, third parties, members of the public and/or our Services.</p>
                <p><b>•	Business transfers.</b> We may disclose your information if we undergo organizational restructuring (e.g., a sale, merger, or acquisition), or in the event of insolvency, bankruptcy, or receivership. In such transactions or events (including in contemplation of transactions, e.g., due diligence), we may disclose your information. If any of our assets are sold or transferred to a third party, your information may be one of the transferred business assets.</p>
                <p><b>•	With your permission.</b> We may disclose your information in ways you consent to at the time of collection or as you otherwise permit us.</p>
                <p>	<b>•	Security</b></p>
                <p>  We take reasonable physical, administrative, and technical steps to help safeguard the information we collect from and about you. While we take efforts to protect the integrity and security of our network and systems, no security measures are perfect, and we cannot guarantee that our security measures will work perfectly. Whenever you transmit information over the Internet or other networks, you do so at your own risk. You are responsible for any activity that takes place under your name or email address.</p>
                <p>	•	Retention</p>
                <p>We retain your information for as long as we deem necessary to fulfill the purposes for which such information was collected. In determining how long to retain information, we take a number of factors into account, including, but not limited to, the nature of the information, the purpose for which the information is used, legal requirements, and technical requirements of our Services (e.g., system backups).</p>
                <p>	•	Your Choices</p>
                <p>	•	Marketing communications
                </p>
                <p>You may opt out of marketing emails from us by clicking the “unsubscribe” link at the bottom of our marketing emails. Please note that if you opt out of marketing communications, we may still send you service-related communications such as emails providing information you have requested or a notice of changes to our legal terms, including this Policy.
                </p>
                <p>	•	Cookies
                </p>
                <p>As mentioned above, we and our vendors may use cookies and similar technologies to collect certain information about you. You may be able to set your internet browser or device to stop accepting new cookies, notice when you receive a new cookie, or disable existing cookies. Please consult the “Help” section of your browser for more information. The setting will apply only to the browser or device you are using, so you will need to reset the setting on a different browser or device. If you disable some or all cookies, the Services may not function as intended and some features may be unavailable.
                </p>
                <p>	•	Online Analytics
                </p>
                <p>We and our vendors may use third-party analytics services on the Services to help us understand how users engage with the Services. These parties may use cookies and other technologies to help collect, analyze, and provide us with reports and/or data.
                </p>
                <p>	•	Online advertising
                </p>
                <p>We may allow third-party advertising technologies (e.g., ad networks and ad servers, or other advertising partners) to place cookies or other tracking technologies on your browser or device to collect information about you to assist in the delivery of relevant advertising on other websites you visit and other online services you use. These ads may be based on your current activity or your activity over time and across other websites and online services and may be tailored to your interests. For example, if you visit our website, you may see ads for GAME on other websites you visit.
                    We neither have access to, nor does this Policy govern, the cookies or other tracking technologies that may be placed on the device you use to access the Services by such non-affiliated third parties. If you are interested in more information about tailored browser advertising and how you can generally control cookies from being put on your computer to deliver tailored advertising, you may visit the Network Advertising Initiative’s Consumer Opt-Out link, the Digital Advertising Alliance’s Consumer Opt-Out link, or Your Online Choices to opt out of receiving tailored advertising from companies that participate in those programs. “Do Not Track”
                    Do Not Track (“DNT”) is a setting that users can enable in certain web browsers. DNT signals do not have a commonly agreed up meaning and we do not currently recognize or respond to DNT signals.</p>
                <p>	•	Supplemental Notice for Non-United States Residents
                </p>
                <p>Please note that GAME is located in the United States. If you are located outside of the United States, please be aware that we process and store your information in the United States. Your information will be protected subject to this Policy and United States laws, which may not be as protective as the data protection laws in your country. To understand your rights with regard to your information, please visit the website of the appropriate data protection authority for your region.</p>
                <p>	•	Changes to This Policy
                </p>
                <p>We may change this Policy from time to time. If we make any changes, we will change the Last Updated date above. We encourage you to read this Policy carefully and regularly check for changes. You acknowledge that your continued use of the Services after we publish changes to this Policy means that the collection, use, and sharing of your information is subject to the updated Policy.</p>
                <p>	•	Contact Us
                </p>
                <p>If you have questions about this Policy, please contact us at info@gothamadvanced.com.
                </p>
                <p>© Copyright 2024 Gotham Advanced Media and Entertainment, LLC 11 Pennsylvania Plaza, 3rd Floor, New York, NY 10001. All rights reserved. Use of any Services which includes a link to this Policy signifies your acceptance of this Policy.
                </p>
            </div>
        </div>
    )
}

export default Privacy