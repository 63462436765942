import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-links">
                <a href="/terms" className="footer-link">Terms of Use</a>
                <a href="/privacy" className="footer-link">Privacy Policy</a>
            </div>
            <div className="footer-rights">
                © 2024 Gotham Advanced Media and Entertainment LLC All Rights Reserved.
            </div>
        </footer>
    )
}

export default Footer