import React from 'react';
import logo from '../../assets/images/logo.png';
import basketball from '../../assets/icons/basketball.png';
import baseball from '../../assets/icons/baseball.png';
import hockey from '../../assets/icons/hockey.png';
import '../../assets/styles/_fonts.scss'
import './Home.scss';
import Footer from '../../Components/Footer/Footer';


function Home() {
    return (
        <>
            <div className='home-container'>
                <div className='logo-container'>
                    <img src={logo} alt="logo" class="responsive-logo" />
                </div>
                <div className='container'>
                    <div className='icons'>
                        <img src={basketball} alt="basketball-icon" />
                        <img src={baseball} alt="basketball-icon" />
                        <img src={hockey} alt="basketball-icon" />
                    </div>
                    <div class="content">
                        <span className='pn-b'>GOTHAM ADVANCED MEDIA AND ENTERTAINMENT (GAME)</span><p className='pn-m' style={{ lineHeight: 1.5 }}> was created by two market-leading regional sports networks - YES and MSG Networks -  to create turnkey streaming solutions for teams, rightsholders and networks to best deliver and monetize their content through a direct relationship with their fans.</p>
                        <p className='pn-m-p' style={{ lineHeight: 1.5 }}> Leveraging the combined experience of these two pioneering sports media companies and sharing their award-winning technical and operational expertise, GAME is offering an innovative, full-stack video streaming platform for DTC/TVE audiences. From design and content delivery across all platforms to ongoing management, technological and marketing services, Gotham Advanced Media and Entertainment is redefining the sports media ecosystem.</p>
                        <div className='contactus'>
                            <span className='es-m'>GET IN THE</span> <span className='es-b'>GAME</span>
                            <div className='contactus-text'> <span className='es-l'> EMAIL US</span> <span className='es-l-b'><a href="mailto:INFO@GOTHAMADVANCED.COM">INFO@GOTHAMADVANCED.COM</a></span></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer'>
                <Footer />
            </div>
        </>

    )
}

export default Home